<template>
  <modal
    ref="modal"
    class-name="flex flex-col min-h-600px w-full md:w-500px 2xl:w-600px"
    @mounted="registerMountedComponent('modal')"
  >
    <h6 class="mb-10">Upload a Valid ID</h6>

    <GovtId
      class="mb-10"
      :is-uploaded="isGovtIdUploadeded"
      @mounted="registerMountedComponent('govtId')"
      @upload-govt-id="evaluateUploadedDocuments"
    />
    <WorkId
      v-show="isEmployed"
      class="mb-10"
      :is-uploaded="isWorkIdUploadeded"
      @mounted="registerMountedComponent('workId')"
      @upload-work-id="evaluateUploadedDocuments"
    />
    <!-- <CustomDocument
      class="mb-10"
      @mounted="registerMountedComponent('customDocument')"
      @upload-work-id="evaluateUploadedDocuments"
    /> -->

    <button
      type="submit"
      class="btn btn-blue btn-md mt-auto relative w-full"
      :disabled="!areAllDocumentsUploaded"
      @click="next"
    >
      <span>Next</span>
      <i class="absolute inline-flex right-20px top-1/2" style="transform: translateY(-50%);">
        <ion-icon name="arrow-forward-outline" />
      </i>
    </button>
  </modal>
</template>

<script>
export default {
  name: 'GovtIdVerification',
  components: {
    // CustomDocument: () => import('./CustomDocument'),
    GovtId: () => import('./GovtId'),
    WorkId: () => import('./WorkId'),
  },
  data() {
    return {
      mountedComponents: {
        // customDocument: false,
        govtId: false,
        modal: false,
        workId: false,
      },
    };
  },
  computed: {
    areAllComponentsMounted() {
      return Object.keys(this.mountedComponents).every((key) => {
        return !!this.mountedComponents[key];
      });
    },
    areAllDocumentsUploaded() {
      return this.isWorkIdUploadeded && this.isGovtIdUploadeded;
    },
    isEmployed() {
      return this.user?.profile?.employment_status === 'employed';
    },
    isGovtIdUploadeded() {
      return !!this.validateUser('govt_id');
    },
    isWorkIdUploadeded() {
      return !!this.validateUser('work_id');
    },
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    evaluateUploadedDocuments() {},
    next() {
      if (!this.areAllDocumentsUploaded) {
        return;
      }
      this.close();
      this.$emit('upload-documents');
    },
    open() {
      this.$refs.modal.open();
    },
    registerMountedComponent(componentName) {
      this.mountedComponents[componentName] = true;

      this.$nextTick(() => {
        if (this.areAllComponentsMounted) {
          this.$emit('mounted');
        }
      });
    },
  }
}
</script>
